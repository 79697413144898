<template>
  <div class="page">
    <h2 class="main-title">{{disabled ? title : `№ ${getTitle}`}}</h2>
    <SubMenu v-if="!disabled"/>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <div class="request">
        <v-row justify="end">
          <v-col cols="4" sm="12" md="4">
            <label>Главному внештатному специалисту</label>
            <v-select
              item-value="id"
              item-text="name"
              :items="getProfiles"
              :rules="rules"
              v-model="query.recipient.profileId"
              class="input"
              required
            ></v-select>
            <label>Минздрава России</label>
            <v-text-field
              placeholder="ФИО"
              :rules="rules"
              v-model="query.recipient.chiefSpecialistFullName"
              class="input"
              required
            ></v-text-field>
            <v-text-field
              placeholder="Адрес"
              :rules="rules"
              v-model="query.recipient.address"
              class="input"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <h4 class="text-center d-flex justify-center align-center"><span>Уважаемый(ая),</span>
          <v-col cols="4" md="4">
            <v-text-field
              v-model="query.recipient.chiefSpecialistMain"
              placeholder="ФИО"
              class="input"
            ></v-text-field>
          </v-col>
        </h4>
        <p>Департамент (наименование Департамента) в соответствии с порядком разработки стандартов медицинской помощи, утвержденным приказом Минздрава России от 22.02.2022 № 103н, и положением о главном внештатном специалисте Министерства здравоохранения Российской Федерации, утвержденным приказом Минздрава России от 25.10.2012 № 444, просит совместно с ФГБУ «ЦЭККМП» Минздрава России обеспечить в срок не позднее
        <span class="date">
          <v-menu
            ref="menu"
            transition="scale-transition"
            offset-y
            min-width="auto"
            >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="query.latestDevelopmentDate"
                placeholder="Дата"
                prepend-icon="mdi-calendar"
                class="input"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
              :first-day-of-week="1"
            ></v-date-picker>
          </v-menu>
        </span>
        подготовку предложений
        в проекты стандартов медицинской помощи на основе клинических рекомендаций, прошедших процедуру одобрения научно-практическим советом, утвержденных медицинскими профессиональными некоммерческими организациями
        и размещенных в Рубрикаторе клинических рекомендаций Минздрава России, согласно приложению, а также обеспечить их размещение в информационной системе ведения и разработки стандартов медицинской помощи.
        </p>
        <p><span class="text-decoration-underline">Приложение:</span> на 1 л. в 1 экз.</p>
        <v-row>
          <v-col cols="6" sm="12" md="6">
            <v-select
              item-value="id"
              item-text="name"
              :rules="rules"
              class="input"
              :items="getDepartmentsl"
              placeholder="Департамент Санитарно-Курортного дела"
              v-model="query.departmentId"
              required
              @change="changeDeportament()"
            ></v-select>
            <p>Директор департамента</p>
            <p><b>{{getDirectorFullName}}</b></p>
          </v-col>
        </v-row>
        <p class="text-end">Приложение к документу</p>
        <p>Перечень клинических рекомендаций, размещенных в Рубрикаторе клинических рекомендаций Минздрава России, для подготовки предложений в проекты стандартов медицинской помощи.</p>
        <v-row>
          <v-col cols="6" md="8" sm="12">
            <v-row
              align="center"
              v-for="(clinicalRecommendation, index) in newClinicalRecommendations"
              :key="index">
              <v-col cols="1" sm="1">
                {{index + 1}}.
              </v-col>
              <v-col cols="5" sm="5">
                <v-select
                  item-value="id"
                  item-text="name"
                  class="input"
                  :items="getAgeGroups"
                  v-model="clinicalRecommendation.ageGroups[0]"
                  placeholder="Любой"
                  @change="moreClinicalRecommendation()"
                ></v-select>
              </v-col>
              <v-col cols="5" sm="5">
                <v-text-field
                  label="Клиническая рекомендация"
                  class="input"
                  v-model="clinicalRecommendation.name"
                  @change="moreClinicalRecommendation()"
                ></v-text-field>
              </v-col>
              <v-col cols="1" sm="1" v-if="buttons">
                <v-btn
                  class="mx-2"
                  color="success"
                  v-if="newClinicalRecommendations.length == index + 1"
                  @click="addClinicalRecommendation()"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
                <v-btn
                  class="mx-2"
                  color="error"
                  v-if="newClinicalRecommendations.length > 1"
                  @click="removeClinicalRecommendation(index)"
                >
                  <v-icon dark>
                    mdi-minus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div class="button-box"
          v-if="
          !(($store.state.login.user.id == $store.state.smp.user || $store.state.login.mainDepartment || $store.state.login.cekkmp)
          && ($store.state.smp.statusTwo || $store.state.smp.statusOne)) || !$store.state.login.gust">
          <v-btn
            color="red"
            :disabled="!valid"
            class="ma-2 white--text"
            v-if="
            ($store.state.login.employee || $store.state.login.administrator) && !this.$route.params.id"
            @click="openPopup()"
          >
            Отправить
          </v-btn>
          <v-btn
            color="red"
            :disabled="!valid"
            class="ma-2 white--text"
            v-if="
            ($store.state.login.gbs|| $store.state.login.cekkmp || $store.state.login.administrator) && $route.params.id && $store.state.smp.statusOne"
            @click="openPopup2()"
          >
            Принятие запроса
          </v-btn>
          <v-btn
            color="white"
            v-if="$store.state.login.role == 'Минздрав' || $store.state.login.administrator"
            class="ma-2 red--text"
            :disabled="disabled"
            @click="createDocument({id: getRequestId.createdId || $route.params.id, name: `№${getTitle}`})"
          >
          <v-icon
            left
            dark
          >
            mdi-cloud-download-outline
          </v-icon>
            Скачать
          </v-btn>
        </div>
      </div>
    </v-form>
      <v-dialog
        v-model="dialog"
        width="500"
      >
      <v-card>
        <v-card-title class="text-h5 text-center green--text lighten-2">
          {{ask ? 'Вы уверены, что хотите отправить запрос?' : 'Запрос на создание стандарта медицинской помощи отправлен'}}
        </v-card-title>
        <v-card-actions v-if="ask">
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="dialog = false"
          >
            Отменить
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="createSMP()"
          >
            Отправить запрос
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-dialog
        v-model="dialog2"
        width="500"
      >
      <v-card>
        <v-card-title class="text-h5 text-center green--text lighten-2">
          {{ask?'Вы уверены, что хотите принять запрос на формирование проекта СМП?':dialogText}}
        </v-card-title>
        <v-card-actions v-if="ask">
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="dialog2 = false"
          >
            Отменить
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="acept()"
          >
            Принять запрос
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import moment from 'moment'
  import SubMenu from '@/components/SubMenu/SubMenu.vue'
  import {mapGetters, mapActions, mapMutations} from 'vuex'

  export default {
    name: 'Request',
    components: {
      SubMenu
    },
    data () {
      return {
        load: false,
        buttons: true,
        title: 'Создание запроса',
        valid: true,
        disabled: true,
        ask: false,
        error: {
          deportament: false,
          profile: false,
        },
        dialog: false,
        dialog2: false,
        dialogText:'',
        rules: [
          value => !!value || 'Это обязательное поле'
        ],
        query: {
          recipient: {
            profileId: null,
            chiefSpecialistFullName: '',
            chiefSpecialistAppeal: '',
            chiefSpecialistMain: '',
            address: ''
          },
          departmentId: null,
          latestDevelopmentDate: this.formatDate(new Date().toISOString().substr(0, 10)),
          clinicalRecommendations: []
        },
        date: new Date().toISOString().substr(0, 10),
        newClinicalRecommendations: [
          {
            name: '',
            ageGroups: []
          }
        ]
      }
    },
    computed : {
      ...mapGetters({
          getProfiles: 'lookups/getProfiles',
          getDepartmentsl: 'lookups/getDepartmentsl',
          getAgeGroups: 'lookups/getAgeGroups',
          getDirectorFullName: 'lookups/getDirectorFullName',
          getUser: 'login/getUser',
          getRequestId: 'request/getRequestId',
          getDocument: 'request/getDocument',
          getTitle: 'smp/getTitle',
          getPasport: 'smp/getPasport'
      }),
      computedDateFormatted () {
        return this.formatDate(this.date)
      }
    },
    methods: {
      ...mapActions({
        lookups: 'lookups/lookups',
        createRequest: 'request/createRequest',
        createDocument: 'request/createDocument',
        standart: 'smp/standart',
        standartUpdate: 'smp/standartUpdate'
      }),
      ...mapMutations({
        mutationDirectorFullName: 'lookups/mutationDirectorFullName',
      }),
      addClinicalRecommendation() {
        this.newClinicalRecommendations.push({
          name: '',
          ageGroups: []
        })
      },
      openPopup() {
        if (this.$refs.form.validate()) {
          this.dialog = true
          this.ask = true
        }
      },
      openPopup2() {
        if (this.$refs.form.validate()) {
          this.dialog2 = true
          this.ask = true
        }
      },
      acept() {
        this.standartUpdate({
          standartId: this.$route.params.id,
          statusId: 'CB2D66F2-8F38-4047-ADA0-9F8CDE03391E',
          stageId: '0DD880C6-ACAA-4F97-9AA2-1E0AD441A5C9'
        }).then(res => {
          this.ask = false
          if (res.status == 200) {
            this.dialogText = 'Успешно'
            this.error = false
          } else {
            this.dialogText = `Ошибка ${res.status}`
            this.error = true
          }
          setTimeout(() => {
            this.dialog2 = false
          }, 3000 )
        }).catch(err => {
          console.log(err)
        })
      },
      removeClinicalRecommendation(index) {
        this.newClinicalRecommendations.splice(index, 1);
      },
      changeDeportament() {
        this.mutationDirectorFullName(this.query.departmentId)
        this.query.recipient.chiefSpecialistAppeal = this.getDirectorFullName
      },
      createSMP() {
        this.query.latestDevelopmentDate = this.formatDate(this.query.latestDevelopmentDate)
        this.ask = false
        this.createRequest(this.query).then(res => {
          this.disabled = false
          setTimeout(() => {
            this.dialog = false
            this.standart(res.data.id)
            this.$router.push({ name: 'request', params: { id: String(res.data.id) } })
          }, 5000 )
        }).catch(err => {
          console.log(err)
        })
      },
      moreClinicalRecommendation() {
        this.query.clinicalRecommendations = this.newClinicalRecommendations
      },
      formatDate (date) {
        if (!date) return null
        let [day, month, year] = date.split('-')
        return `${year}-${month}-${day}`
      },
    },

    watch: {
      date (val) {
        this.query.latestDevelopmentDate = this.formatDate(val)
      }
    },
    mounted() {
      this.buttons = true
      if (this.$route.params.id) {
        this.disabled = false
        this.standart(this.$route.params.id).then(res => {
          this.buttons = false
          this.load = true
          this.query = {
            recipient: {
              profileId: res.data.recipient[0].profileId,
              chiefSpecialistFullName: res.data.recipient[0].chiefSpecialistFullName,
              chiefSpecialistAppeal: res.data.recipient[0].chiefSpecialistAppeal,
              address: res.data.recipient[0].address,
              chiefSpecialistMain: res.data.recipient[0].chiefSpecialistMain,
            },
            departmentId: res.data.passportData.departmentId,
            latestDevelopmentDate: moment(res.data.passportData.latestDevelopmentDate).format('DD-MM-YYYY'),
            clinicalRecommendations: res.data.clinicalRecommendations.map(item => {
              let ageGroup = "ff4e5efe-138c-4b91-b45d-9013644a8965"
              if (item.ageGroups[0] === 'Любой') {
                ageGroup = '413664c0-8378-43ae-8d2d-488b9a884787'
              } else if (item.ageGroups[0] === 'Дети') {
                ageGroup = '82ede3fb-5653-4189-98bf-7be3d809b41b'
              } else {
                ageGroup = "ff4e5efe-138c-4b91-b45d-9013644a8965"
              }
              return {name: item.clinicalRecommendationName, ageGroups: [ageGroup]}
            })
          }
          this.mutationDirectorFullName(this.query.departmentId)
          this.newClinicalRecommendations = this.query.clinicalRecommendations
        })
      }
      this.lookups()
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@/scss/vars.scss";
  @import "~@/scss/mixins.scss";
  @import "./style.scss";
</style>
